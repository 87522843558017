import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"

import flowersOnChairImage from "../assets/contact/wild-flowers.jpg"

export default function Contact({ data }) {
  return (
    <Layout title="Contact" description="You can contact Anne Pitman by filling out this form or by sending an email.">
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column content">
              <h2 className="title is-2 has-text-tertiary">Contact</h2>
              <p>If you have any questions or would like to book an appointment, please send an email or fill out the form below.</p>
              <p><a href="mailto:anne@embodiedyogatherapy.com">anne@embodiedyogatherapy.com</a></p>

              <form name="Contact Form" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="Contact Form" />


                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-inline-block-desktop">
                      <div className="control">      
                        <label className="label has-text-primary">First name <span className="required">*</span></label>
                        <div className="control">
                          <input name="First name" className="input is-primary  has-text-primary" type="text" placeholder="" required />
                        </div>
                      </div>
                    </div>


                    <div className="field is-inline-block-desktop">
                      <div className="control">
                        <label className="label has-text-primary">Last name <span className="required">*</span></label>
                        <div className="control">
                          <input name="Last name" className="input is-primary has-text-primary" type="text" placeholder="" required />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      

                <div className="field">
                  <label className="label has-text-primary">Email <span className="required">*</span></label>
                  <p className="control has-icons-left has-icons-right">
                    <input name="Email" className="input is-primary has-text-primary" type="email" placeholder="" required />
                    <span className="icon is-small is-left">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.961" height="15.721" viewBox="0 0 20.961 15.721"><path class="a" d="M20.564,69.191a.246.246,0,0,1,.4.192v8.372A1.966,1.966,0,0,1,19,79.721H1.965A1.966,1.966,0,0,1,0,77.756V69.388A.245.245,0,0,1,.4,69.2c.917.712,2.133,1.617,6.309,4.651.864.63,2.321,1.957,3.775,1.949,1.462.012,2.948-1.343,3.779-1.949C18.435,70.812,19.647,69.9,20.564,69.191ZM10.48,74.48c.95.016,2.317-1.2,3-1.695,5.433-3.942,5.846-4.286,7.1-5.269a.98.98,0,0,0,.377-.774v-.778A1.966,1.966,0,0,0,19,64H1.965A1.966,1.966,0,0,0,0,65.965v.778a.986.986,0,0,0,.377.774c1.253.978,1.666,1.326,7.1,5.269C8.163,73.285,9.531,74.5,10.48,74.48Z" transform="translate(0 -64)"/></svg>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <label className="label has-text-primary">Message <span className="required">*</span></label>
                  <div className="control">
                    <textarea name="Message" className="textarea is-primary has-text-primary" type="textarea" placeholder="" required />
                  </div>
                </div>

                <button className="button is-primary half transition" type="submit">SUBMIT</button>
              </form>

            </div>
            <div className="column">
              <img src={flowersOnChairImage} alt="Wild flowers in the sun" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}